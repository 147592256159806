<template>
  <v-simple-table
  class="mt-5 mx-5"
    fixed-header
    height="100%"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width:70%" class="text-left">
            Cluster Head
          </th>
          <th style="width:30%" class="text-left">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in item" :key="index"
        >
            <!-- <td>{{item.cluster_head_name}} ({{item.cluster_name}})</td> -->

            <td>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{item.cluster_head_name}} ({{item.cluster_name}})
                  </span>
                </template>
                  <span v-if="item.reports_summary_status.length != 0">Last Generate: {{item.reports_summary_status[0].generated_by}}</span>
                  <span v-else>No Available Records</span>
                  <br>
                  <span v-if="item.reports_summary_status.length != 0">Date: {{formatDate(item.reports_summary_status[0].updated_at)}}</span>
              </v-tooltip>
            </td>

        
            <v-btn
              v-if="currUser.user_role === 'admin' || currUser.user_role === 'auditor' || currUser.user_role === 'super_admin' || currUser.user_role === 'approver' || currUser.is_evaluation_committee === '1' || currUser.cluster_no == item.cluster_no || currUser.is_det_encoder === '1' || currUser.is_avp === '1'  || currUser.is_control_committee === '1'"
                class="my-2"
                color="primary"
                @click="generate(item)"
                >
                Generate Report 
            </v-btn>
            <v-btn
                v-else
                class="my-2"
                color="primary"
                disabled 
                >
                Generate Report
            </v-btn>

            <v-btn
              v-if="item.reports_summary_status.length != 0 && item.reports_summary_status[0].gcp_link != null && (currUser.user_role === 'admin' || currUser.user_role === 'auditor' || currUser.user_role === 'super_admin' || currUser.user_role === 'approver' || currUser.is_evaluation_committee === '1' || currUser.cluster_no == item.cluster_no || currUser.is_det_encoder === '1' || currUser.is_avp === '1'  || currUser.is_control_committee === '1')"
                class="ml-3"
                color="success"
                @click="download(item)"
                >
                Download 
            </v-btn>
            <v-btn
              v-else-if="item.reports_summary_status.length != 0 && item.reports_summary_status[0].gcp_link == null && (currUser.user_role === 'admin' || currUser.user_role === 'auditor' || currUser.user_role === 'super_admin' || currUser.user_role === 'approver' || currUser.is_evaluation_committee === '1' || currUser.cluster_no == item.cluster_no || currUser.is_det_encoder === '1' || currUser.is_avp === '1'  || currUser.is_control_committee === '1')"
                class="ml-3"
                color="success"
                disabled
                >
                Generating 
            </v-btn>
            <v-btn
                v-else
                class="ml-3"
                color="success"
                disabled
                >
                Download 
            </v-btn>

        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseISO, format } from 'date-fns'

export default{
    data () {
      return {
        item: ''
      }
    },
    computed: {
      ...mapGetters({
          currUser: 'auth/currUser'
      })
    },
    async mounted () {
        this.reloadCluster()
    },
    methods: {
      async download(i){
        console.log(i.reports_summary_status[0].gcp_link.url)
        console.log(i)
        const payload = {
          file: i.reports_summary_status[0].gcp_link.url
        }
        const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
        console.log(res)
        var extension = res.data.result.extension
        var file = Buffer.from(res.data.result.file, 'base64')
        var blob = ''
        if (extension === 'pdf') {
          blob = new Blob([file], { type: 'application/pdf' })
        } else if (extension === 'jpg') {
          blob = new Blob([file], { type: 'image/jpg' })
        } else if (extension === 'png') {
          blob = new Blob([file], { type: 'image/png' })
        } else if (extension === 'jpeg') {
          blob = new Blob([file], { type: 'image/jpeg' })
        } else if (extension === 'xlsm') {
            blob = new Blob([file], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
        } else if (extension === 'xlsx') {
            blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        }
        let currentDate = new Date().toJSON().slice(0, 10);
        var link = document.createElement('a');
        var fileURL = window.URL.createObjectURL(blob);
        link.href = fileURL;
        link.download = 'status_cluster:' + i.cluster_no + '_' + currentDate;
        link.click();
        link.remove();
        window.URL.revokeObjectURL(fileURL);
      },
      async reloadCluster(){
        const res = await this.$store.dispatch('salesProspecting/doGetAllCluster')
        console.log(res)

        res.data.result.map(res => {
          if (res.reports_summary_status.length != 0){
            res.reports_summary_status[0].gcp_link = JSON.parse(res.reports_summary_status[0].gcp_link)
          }
        })
        
        this.item = res.data.result
        console.log(this.item)
      },
      async generate(i){
        const res = await this.$store.dispatch('reportsGeneration/doGetStatusCluster', i.cluster_no)
        if (res.status === 200){
          this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Generating Summary of Status Per Cluster','messagetype':'orange'});
          this.reloadCluster()
        } else if (res.status === 206 || res.code === 400 || res.code === 422){
          this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
          this.reloadCluster()
        } else {
          this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
          this.reloadCluster()
        }
      },
      formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
      },
    },
    
}
</script>
