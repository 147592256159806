<template>
  <v-simple-table
  class="mt-5 mx-5"
    fixed-header
    height="100%"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width:70%" class="text-left">
            Title
          </th>
          <th style="width:30%" class="text-left">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>

        <td>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >Generate PRP Directory Overall
              </span>
            </template>
              <span v-if="reports.length != 0">Last Generate: {{reports[0].generated_by}}</span>
              <span v-else>No Available Records</span>
              <br>
              <span v-if="reports.length != 0">Date: {{formatDate(reports[0].updated_at)}}</span>
          </v-tooltip>
        </td>

          <v-btn
              v-if="currUser.user_role === 'admin' || currUser.user_role === 'auditor' || currUser.user_role === 'super_admin' || currUser.user_role === 'approver' || currUser.is_evaluation_committee === '1' || currUser.is_det_encoder === '1' || currUser.is_avp === '1' || currUser.is_control_committee === '1'"
              class="my-2"
              color="primary"
              @click="generate"
              >
              Generate Report 
          </v-btn>
          <v-btn
              v-else
              class="my-2"
              color="primary"
              disabled
              >
              Generate Report 
          </v-btn>

          <v-btn
              v-if="reports.length != 0 && reports[0].gcp_link != null && (currUser.user_role === 'admin' || currUser.user_role === 'auditor' || currUser.user_role === 'super_admin' || currUser.user_role === 'approver' || currUser.is_evaluation_committee === '1' || currUser.is_det_encoder === '1' || currUser.is_avp === '1' || currUser.is_control_committee === '1')"
              class="ml-3"
              color="success"
              @click="retrieveUploaded(reports[0].gcp_link.url)"
              >
              Download 
          </v-btn>
          <v-btn
              v-else-if="reports.length != 0 && reports[0].gcp_link == null && (currUser.user_role === 'admin' || currUser.user_role === 'auditor' || currUser.user_role === 'super_admin' || currUser.user_role === 'approver' || currUser.is_evaluation_committee === '1' || currUser.is_det_encoder === '1' || currUser.is_avp === '1' || currUser.is_control_committee === '1')"
              class="ml-3"
              color="success"
              disabled
              >
              Generating 
          </v-btn>
          <v-btn
              v-else
              class="ml-3"
              color="success"
              disabled
              >
              Download 
          </v-btn>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseISO, format } from 'date-fns'

export default{
   data () {
      return {
        item: ['1'],
        reports: [],
      }
    },
    computed: {
      ...mapGetters({
          currUser: 'auth/currUser'
      })
	},
  async mounted () {
    this.reloadReports()
  },
  methods:{
    async reloadReports() {
      this.reports = []
      const res = await this.$store.dispatch('reportsGeneration/doGetReports')
      if (res.data.result.length != 0){
                console.log(res)
                res.data.result.reports_prp_directory.map(res => {
                    res.gcp_link = JSON.parse(res.gcp_link)
                })
                res.data.result.reports_prp_directory.map(res => {
                    if (res.cluster_no == 'overall'){
                    this.reports.push(res)
                    }
                })
            }
		},
    async generate(){
      const res = await this.$store.dispatch('reportsGeneration/doGetPrpDirectoryOverall')
      console.log(res)
      if (res.status === 200){
        this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Generating PRP Directory','messagetype':'orange'});
        this.reloadReports()
      } else if (res.status === 206 || res.code === 400 || res.code === 422){
        this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
        this.reloadReports()
      } else {
        this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
        this.reloadReports()
      }
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
        // return moment().format('MMMM Do YYYY, h:mm:ss a');
    },
    download(){
      console.log(this.link)
    },
    async retrieveUploaded (item) {
			const payload = {
				file: item
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
      console.log(res)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsm') {
          blob = new Blob([file], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
      } else if (extension === 'xlsx') {
          blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      }
      let currentDate = new Date().toJSON().slice(0, 10);
			var link = document.createElement('a');
      var fileURL = window.URL.createObjectURL(blob);
      link.href = fileURL;
      link.download = 'prp_directory_overall_' + currentDate;
      link.click();
      link.remove();
      window.URL.revokeObjectURL(fileURL);
		},
  }
}
</script>
