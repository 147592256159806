<template>
  <v-simple-table
  class="mt-5 mx-5"
    fixed-header
    height="100%"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width:70%" class="text-left">
            Title
          </th>
          <th style="width:30%" class="text-left">
            Action
          </th>
        </tr>
      </thead>
      <tbody>

        <tr v-for="(item, parentIndex) in templates" :key="parentIndex">
    
            <td>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'entity_sos'">
                    SOS Entity Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'location_sos'">
                    SOS Location Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'merchant_wu'">
                    Merchant WU Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'entity_is_import'">
                    Entity IS Import Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'location_is_import'">
                    Location IS Import Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'location_prp_import'">
                    IS Standalone Location Import
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'charges'">
                    Finance Charges Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'commisions_trail'">
                    Finance Commissions Trail Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'commission'">
                    Finance Commissions Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'def_bank_accounts'">
                    Finance Bank Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'email'">
                    Finance Email Template
                    </span>
                    <span v-bind="attrs" v-on="on" key
                        v-if="item.report_type == 'manpower'">
                    Finance Manpower Template
                    </span>
                    </template>
                    <span>Last Generate: {{item.generated_by}}</span>
                    <br>
                    <span>Date: {{formatDate(item.updated_at)}}</span>
                </v-tooltip>
            </td>

            <v-btn
              v-if="currUser.user_role === 'admin' || currUser.user_role === 'auditor' || currUser.user_role === 'super_admin' || currUser.user_role === 'approver' || currUser.is_evaluation_committee === '1' || currUser.is_det_encoder === '1' || currUser.is_avp === '1' || currUser.is_control_committee === '1'"
              class="my-2"
              color="primary"
              @click="generate(item.report_type)"
              >
              Generate Template 
            </v-btn>
            <v-btn
              v-else
              class="my-2"
              color="primary"
              disabled
              >
              Generate Template 
            </v-btn>

            <v-btn
              v-if="item.length != 0 && item.gcp_link != null && (currUser.user_role === 'admin' || currUser.user_role === 'auditor' || currUser.user_role === 'super_admin' || currUser.user_role === 'approver' || currUser.is_evaluation_committee === '1' || currUser.is_det_encoder === '1' || currUser.is_avp === '1' || currUser.is_control_committee === '1')"
              class="ml-3"
              color="success"
              @click="retrieveUploaded(item)"
              >
              Download 
          </v-btn>
          <v-btn
              v-else-if="item.length != 0 && item.gcp_link == null && (currUser.user_role === 'admin' || currUser.user_role === 'auditor' || currUser.user_role === 'super_admin' || currUser.user_role === 'approver' || currUser.is_evaluation_committee === '1' || currUser.is_det_encoder === '1' || currUser.is_avp === '1' || currUser.is_control_committee === '1')"
              class="ml-3"
              color="success"
              disabled
              >
              Generating 
          </v-btn>
          <v-btn
              v-else
              class="ml-3"
              color="success"
              disabled
              >
              Download 
          </v-btn>
      
        </tr>

      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { parseISO, format } from 'date-fns'

export default{
   data () {
      return {
        item: ['1'],
        reports: [],
        templates: []
      }
    },
    computed: {
      ...mapGetters({
          currUser: 'auth/currUser'
      })
	},
  async mounted () {
    this.reloadReports()
  },
  methods:{
    async reloadReports() {
      this.reports = []
      const res = await this.$store.dispatch('reportsGeneration/doGetReports')
        if (res.data.result.length != 0){
            if (res.data.result.reports_prp_template.length != 0){
                res.data.result.reports_prp_template.map(res => {
                    res.gcp_link = JSON.parse(res.gcp_link)
                })
                this.templates = res.data.result.reports_prp_template
            }
        }
    },
    async generate(report_type){
        if(report_type == 'entity_sos'){
            const res = await this.$store.dispatch('reportsGeneration/doGetSosEntityTemplate')
            this.checkRes(res)
        } else if (report_type == 'location_sos'){
            const res = await this.$store.dispatch('reportsGeneration/doGetSosLocationTemplate')
            this.checkRes(res)
        } else if (report_type == 'merchant_wu'){
            const res = await this.$store.dispatch('reportsGeneration/doGetMerchantWuTemplate')
            this.checkRes(res)
        } else if (report_type == 'entity_is_import'){
            const res = await this.$store.dispatch('reportsGeneration/doGetEntityIsTemplate')
            this.checkRes(res)
        } else if (report_type == 'location_is_import'){
            const res = await this.$store.dispatch('reportsGeneration/doGetLocationIsTemplate')
            this.checkRes(res)
        } else if (report_type == 'location_prp_import'){
            const res = await this.$store.dispatch('reportsGeneration/doGetLocationPrpTemplate')
            this.checkRes(res)
        }

        // Finance

        else if (report_type == 'charges'){
            const res = await this.$store.dispatch('reportsGeneration/doGetChargesFinance')
            this.checkRes(res)
        }
        else if (report_type == 'commisions_trail'){
            const res = await this.$store.dispatch('reportsGeneration/doGetCommissionTrailFinance')
            this.checkRes(res)
        }
        else if (report_type == 'commission'){
            const res = await this.$store.dispatch('reportsGeneration/doGetCommissionFinance')
            this.checkRes(res)
        }
        else if (report_type == 'def_bank_accounts'){
            const res = await this.$store.dispatch('reportsGeneration/doGetDefBankAccFinance')
            this.checkRes(res)
        }
        else if (report_type == 'email'){0
            const res = await this.$store.dispatch('reportsGeneration/doGetEmailFinance')
            this.checkRes(res)
        }
        else if (report_type == 'manpower'){
            const res = await this.$store.dispatch('reportsGeneration/doGetManpowerFinance')
            this.checkRes(res)
        }
    },
    checkRes(res){
        if (res.status === 200){
        this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Generating Template','messagetype':'orange'});
        this.reloadReports()
        } else if (res.status === 206 || res.code === 400 || res.code === 422 || res.code === 422){
            this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            this.reloadReports()
        } else {
            this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
            this.reloadReports()
        }
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
        // return moment().format('MMMM Do YYYY, h:mm:ss a');
    },
    async retrieveUploaded (item) {
        const payload = {
            file: item.gcp_link.url
        }
        const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
        var extension = res.data.result.extension
        var file = Buffer.from(res.data.result.file, 'base64')
        var blob = ''
        if (extension === 'pdf') {
            blob = new Blob([file], { type: 'application/pdf' })
        } else if (extension === 'jpg') {
            blob = new Blob([file], { type: 'image/jpg' })
        } else if (extension === 'png') {
            blob = new Blob([file], { type: 'image/png' })
        } else if (extension === 'jpeg') {
            blob = new Blob([file], { type: 'image/jpeg' })
        } else if (extension === 'xlsm') {
            blob = new Blob([file], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
        } else if (extension === 'xlsx') {
            blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        } else if (extension === 'csv') {
            blob = new Blob([file], { type: 'text/csv' })
        }
        var link = document.createElement('a');
        var fileURL = window.URL.createObjectURL(blob);
        link.href = fileURL;

        if (item.report_type == 'entity_sos'){
            link.download = 'SOS Entity Template';
        } else if (item.report_type == 'location_sos'){
            link.download = 'SOS Location Template';
        }else if (item.report_type == 'merchant_wu'){
            link.download = 'Merchant WU Template';
        }else if (item.report_type == 'entity_is_import'){
            link.download = 'Entity IS Import Template';
        }else if (item.report_type == 'location_is_import'){
            link.download = 'Location IS Import Template';
        }else if (item.report_type == 'location_prp_import'){
            link.download = 'Location PRP Import Template';
        }else if (item.report_type == 'charges'){
            link.download = 'Finance Charges Template';
        }else if (item.report_type == 'commisions_trail'){
            link.download = 'Finance Commissions Trail Template';
        }else if (item.report_type == 'commission'){
            link.download = 'Finance Commissions Template';
        }else if (item.report_type == 'def_bank_accounts'){
            link.download = 'Finance Bank Template';
        }else if (item.report_type == 'email'){
            link.download = 'Finance Email Template';
        }else if (item.report_type == 'manpower'){
            link.download = 'Finance Manpower Template';
        }

        link.click();
        link.remove();
        window.URL.revokeObjectURL(fileURL);
		},
  }
}
</script>
