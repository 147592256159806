<template>
  <v-card color="white">
    <v-card-title class="text-center justify-left px-3">
      <h1 class="font-weight-bold text-h5 indigo--text">
        Generate Reports
      </h1>
    </v-card-title>
    <v-card-text>
      <h4>Due to the load of data being generated, please take note that generation of</h4>
      <h4>Excel file can only occur once every 5 minutes only.</h4>
    </v-card-text>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="indigo"
      grow
    >
      
        <v-tab>Master File</v-tab>
        <v-tab>Mailing Address</v-tab>
        <v-tab>Summary of Status</v-tab>
        <v-tab>PRP Type</v-tab>
        <v-tab>Completion Rate</v-tab>
        <v-tab>Business Partners per Area</v-tab>
        <v-tab>PRP Directory</v-tab>
        <v-tab>Import Templates</v-tab>
        <v-tab>IS Directory</v-tab>
        <v-tab>User Reports</v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">

        <v-tab-item>
          <h3 class="mx-5 my-5">Overall</h3>
          <Masterfile/>
          <h3 class="mx-5 my-5">Per Cluster</h3>
          <MasterPerCluster/>
        </v-tab-item>

        <v-tab-item>
          <h3 class="mx-5 my-5">Overall</h3>
          <MailingAddressOverall/>
          <h3 class="mx-5 my-5">Per Cluster</h3>
          <MailingAddressCluster/>
        </v-tab-item>
      
        <v-tab-item>
          <h3 class="mx-5 my-5">Overall</h3>
          <StatusOverall/>
          <h3 class="mx-5 my-5">Per Cluster</h3>
          <StatusCluster/>
        </v-tab-item>

        <v-tab-item>
          <h3 class="mx-5 my-5">Overall</h3>
          <PrpTypeOverall/>
          <h3 class="mx-5 my-5">Per Cluster</h3>
          <PrpTypeCluster/>
        </v-tab-item>

        <v-tab-item>
          <h3 class="mx-5 my-5">Overall</h3>
          <CompletionRate/>
          <h3 class="mx-5 my-5">Per Cluster</h3>
          <CompletionRateCluster/>
        </v-tab-item>

        <v-tab-item>
          <h3 class="mx-5 my-5">Overall</h3>
          <BusinessPartnerOverall/>
          <h3 class="mx-5 my-5">Per Cluster</h3>
          <BusinessPartnerCluster/>
        </v-tab-item>

        <v-tab-item>
          <h3 class="mx-5 my-5">Overall</h3>
          <PrpDirectoryOverall/>
          <h3 class="mx-5 my-5">Per Cluster</h3>
          <PrpDirectoryCluster/>
        </v-tab-item>

        <v-tab-item>
          <h3 class="mx-5 my-5">Excel Templates</h3>
          <ExcelTemplate/>
        </v-tab-item>

        <v-tab-item>
          <h3 class="mx-5 my-5">IS Directory</h3>
          <IsDirectoryReport/>
        </v-tab-item>

        <v-tab-item>
          <h3 class="mx-5 my-5">User Reports</h3>
          <UserReport/>
        </v-tab-item>

    </v-tabs-items>
  </v-card>
</template>


<script>

import Masterfile from './Masterfile.vue';
import MasterPerCluster from './MasterPerCluster.vue';
import MailingAddressOverall from './MailingAddressOverall.vue';
import MailingAddressCluster from './MailingAddressCluster.vue';
import StatusOverall from './StatusOverall.vue';
import StatusCluster from './StatusCluster.vue';
import PrpTypeCluster from './PrpTypeCluster.vue';
import CompletionRateCluster from './CompletionRateCluster.vue';
import PrpTypeOverall from './PrpTypeOverall.vue';
import CompletionRate from './CompletionRate.vue';
import BusinessPartnerCluster from './BusinessPartnerCluster.vue';
import BusinessPartnerOverall from './BusinessPartnerOverall.vue';
import PrpDirectoryOverall from './PrpDirectoryOverall.vue';
import PrpDirectoryCluster from './PrpDirectoryCluster.vue';
import ExcelTemplate from './ExcelTemplate.vue';
import UserReport from './UserReport.vue';
import IsDirectoryReport from './IsDirectoryReport.vue';

  export default {
    data () {
      return {
        tab: null,
      }
    },
    components:{
        Masterfile: Masterfile,
        MasterPerCluster: MasterPerCluster,
        MailingAddressOverall: MailingAddressOverall,
        MailingAddressCluster: MailingAddressCluster,
        StatusOverall: StatusOverall,
        StatusCluster: StatusCluster,
        PrpTypeOverall: PrpTypeOverall,
        CompletionRate: CompletionRate,
        BusinessPartnerOverall: BusinessPartnerOverall,
        PrpTypeCluster: PrpTypeCluster,
        CompletionRateCluster: CompletionRateCluster,
        BusinessPartnerCluster: BusinessPartnerCluster,
        PrpDirectoryOverall: PrpDirectoryOverall,
        PrpDirectoryCluster: PrpDirectoryCluster,
        ExcelTemplate: ExcelTemplate,
        UserReport: UserReport,
        IsDirectoryReport: IsDirectoryReport,
    },
  }
</script>

<style>
/* Helper classes */
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>